export const Status = [
    'new',
    'irrelevant',
    'inProgress',
    'interviewScheduled',
    'interviewed',
    'recruited',
    'withdrawed',
    'interviewEndProcess'
];
