import { AnalyticsProvider } from "@/components/context/analytics-context";
import { VideoFeedWidget } from "@/components/video-feed-widget";
import React from "react";
import { createRoot, Root } from "react-dom/client";
import "./index.css";
import { getProjectSDK } from "./lib/api";
import { SDKData } from "./types";

interface WidgetConfig {
  apiKey: string;
  position?: "bottom-right" | "bottom-left";
  variant?: "widget" | "story" | "lightbox";
  theme?: {
    primaryColor: string;
    textColor: string;
  };
}

let root: Root | null = null;
let currentConfig: WidgetConfig | null = null;
let currentSdkData: SDKData | null = null;

export const initialize = async (
  config: WidgetConfig,
  overrideSdkData: Partial<SDKData>
) => {
  currentConfig = config;
  const container = document.createElement("div");
  container.id = "webreels-sdk-root";
  container.classList.add("webreels");
  document.body.appendChild(container);

  if (!config.apiKey) {
    throw new Error("WebReels: API key is required");
  }

  const { data: sdkData } = await getProjectSDK(config.apiKey);

  const mergedSdkData = { ...sdkData, ...overrideSdkData };

  root = createRoot(container);
  renderWidget(container, config, mergedSdkData);

  return () => {
    root?.unmount();
  };
};

const renderWidget = (
  container: HTMLElement,
  config: WidgetConfig,
  sdkData: SDKData
) => {
  root?.render(
    <div dir="ltr" className="relative z-[9999999999]">
      <AnalyticsProvider projectId={sdkData.id} apiKey={config.apiKey}>
        <VideoFeedWidget
          {...config}
          variant="widget"
          data={sdkData}
          apiKey={config.apiKey}
          container={container}
        />
      </AnalyticsProvider>
    </div>
  );
};

export const updateConfig = async (
  newConfig: Partial<WidgetConfig>,
  newSdkData: Partial<SDKData>
) => {
  if (!currentConfig || !root) {
    throw new Error("WebReels: Widget not initialized. Call initialize first.");
  }

  // If API key is changed, fetch new SDK data
  if (newConfig.apiKey && newConfig.apiKey !== currentConfig.apiKey) {
    const { data: sdkData } = await getProjectSDK(newConfig.apiKey);
    currentSdkData = { ...currentSdkData, ...sdkData, ...newSdkData };
  }

  // Update current config with new values
  currentConfig = {
    ...currentConfig,
    ...newConfig,
  };

  // Get container element
  const container = document.getElementById("webreels-sdk-root");
  if (!container) {
    throw new Error("WebReels: Widget container not found");
  }

  // Re-render with updated config
  renderWidget(container, currentConfig, currentSdkData!);
};

export const unmount = () => {
  root?.unmount();
  root = null;
  currentConfig = null;
  currentSdkData = null;
};
